<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Cài đặt mẫu phiếu thu</b-card-title>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          lg="9"
        >
          <validation-observer
            #default="{ handleSubmit, invalid }"
            ref="refFormObserver"
          >
            <b-form
              @submit.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
            >
              <b-row>
                <b-col cols="12">
                  <validation-provider
                    #default="validationContext"
                    name="incomeTemplate"
                    rules="required"
                  >
                    <b-form-group>
                      <template v-slot:label>
                        Mẫu phiếu thu <span class="text-danger">(*)</span>
                      </template>
                      <editor
                        v-model="incomeTemplate"
                        :default-value="incomeTemplate"
                      />
                      <small class="text-danger">{{
                        validationContext.errors[0]
                      }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-button
                    class="mt-2"
                    variant="primary"
                    block
                    type="submit"
                    :disabled="invalid"
                  >
                    Lưu
                  </b-button>
                </b-col>

                <hr>

              </b-row></b-form>
          </validation-observer>
        </b-col>
        <b-col
          cols="12"
          lg="3"
        >
          <attribute-table />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BForm, BRow, BCol, BFormGroup, BButton, BCard, BCardHeader, BCardTitle, BCardBody,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Editor from '@/views/components/Editor.vue';
import useJwt from '@/auth/jwt/useJwt';
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
import AttributeTable from '../AttributeTable.vue';

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,

    Editor,
    AttributeTable,
  },
  data() {
    return {
      incomeTemplate: '',
      // validation
      required,
      email,
    };
  },

  created() {
    this.fetchData();
  },
  setup() {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    return {

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
  methods: {
    fetchData() {
      useJwt.getUserConfiguration()
        .then(response => {
          this.incomeTemplate = response.data.income_template;
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Thông báo`,
              icon: 'UserIcon',
              variant: 'danger',
              text: `Có lỗi xảy ra khi lấy thông tin cài đặt. Vui lòng thử lại sau`,
            },
          });
        });
    },
    onSubmit() {
      useJwt.updateUserConfiguration({ income_template: this.incomeTemplate })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Thông báo`,
              icon: 'UserIcon',
              variant: 'success',
              text: `Thông tin đã được cập nhật thành công`,
            },
          });
        }).catch(error => {
          if (error.response && error.response.status === 422 && error.response.data && error.response.data.errors) {
            this.$refs.refFormObserver.setErrors(error.response.data.errors);
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Thông báo',
                text: error.response.data.message ? error.response.data.message : 'Có lỗi xảy ra, vui lòng thử lại sau',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            });
          }
        });
    },
  },
};
</script>
